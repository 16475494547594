(function ($) {
    $.fn.headerScroll = function () {
        return this.each(function () {
            var header = $(this);
            var headerHeight = header.height();
            var body = $('body');

            header.before(header.clone().addClass("scrolling"));
            $(window).scroll(function () {
                $("body").toggleClass("down", ($(window).scrollTop() > headerHeight));
            });
        });
    };
}(jQuery));