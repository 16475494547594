$(function () {
    //global
    theme();
    //index
    owl();
    faq();
    new WOW().init();
    animateNumber();
});

function theme() {
    var $body = $('body');
    var $header = $('.header');
    var $menuNavToggle = $('.menu-nav-toggle');
    var $hamburgerToggle = $('.hamburger-toggle');
    var $dropsubBtn = $('.dropsub-btn');
    var $submenu = $('.submenu');
    var $removeMask = $('.remove-mask');

    $dropsubBtn.on('click', function () {
        console.log('下拉按鈕');
        // $submenu.slideToggle();
        // $dropsubBtn.toggleClass('active');

        var $nowDropsubBtn = $(this);
        $nowDropsubBtn.toggleClass('active');
        $nowDropsubBtn.parent().find('.submenu').slideToggle();

    });
    $menuNavToggle.on('click', function () {
        $body.toggleClass('active');
        $header.toggleClass('active');
    });
    $hamburgerToggle.on('click', function () {
        console.log('hamburger click');
        $body.toggleClass('active');
        $header.toggleClass('active');
        $submenu.hide();
    });
    $removeMask.on('click', function () {
        $body.toggleClass('active');
        $header.toggleClass('active');
    });
}

function owl() {
    $('.news-carousel').owlCarousel({
        autoplay: true,
        dots: false,
        loop: true,
        nav: false,
        margin: 10,
        responsiveClass: true,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2,
                margin: 5
            },
            992: {
                items: 3
            }
        }
    });
}

function faq() {
    $faqItem = $('.faq-item');
    $faqItem.on('click', function () {
        $(this).toggleClass('active');
        $(this).find('.faq-item-answer').slideToggle();
    })
}


function animateNumber() {
    var $animate_number = $('.animate-number');

    if ($animate_number.length > 0) {
        $animate_number.each(function () {
            var $this = $(this);
            $this.data('isAnimate', false);

            if (!$this.hasClass('animate-stop')) {
                // if ($this.isInViewport()) {
                //     $this.animateNumber({
                //         number: $this.attr("data-value")
                //     }, 750);
                //     $this.addClass('animate-stop');
                // }

                $this.on('inview', function (event, isInView) {
                    if (isInView) {
                        if ($this.data('isAnimate') == false) {
                            $this.animateNumber({
                                number: $this.attr("data-value"),
                                numberStep: function(now, tween) {
                                    console.log('[animateNumber] numberStep');
                                   
                                    var floored_number = Math.floor(now),
                                    $target = $(tween.elem);
                                    console.log($target.data('unit'));
                                    if(typeof($target.data('unit'))!='undefined'){
                                        $target.text(floored_number + $target.data('unit'));
                                    }else{
                                        $target.text(floored_number);
                                    }
                                   
                                  }
                            }, 750);
                            $this.addClass('animate-stop');
                            $this.data('isAnimate', true);
                        }

                    }
                });
            }
        });
    }
}
