$(document).ready(function () {
    //global
    common();
    goToTop();
    question();
    loadGoogleFont();
    //index
    bannerSlider();
    //product
    // productDetailSlider();
    // productSharing();
    //blog
    blogSharing();
});

function bannerSlider() {
    var bSlider = null;

    var $bannerSlider = $('#bannerSlider');
    if ($bannerSlider.length > 0) {

        if ($bannerSlider.find('.ms-view').length == 0) {
            setMasterSliderImageOnScreen($bannerSlider);

            try {
                bSlider = new MasterSlider();
                // adds Arrows navigation control to the slider.

                bSlider.control('timebar', {
                    insertTo: '#bannerSlider'
                });
                bSlider.control('bullets',{
                    autohide:false
                });

                bSlider.control('circletimer', {
                    autohide: false,
                    overVideo: true,
                    color: '#FFFFFF',
                    radius: 4,
                    stroke: 9
                });

                bSlider.control('arrows', {
                    autohide: true
                });
                bSlider.setup('bannerSlider', {
                    width: 1280, // slider standard width
                    height: 640, // slider standard height
                    minHeight: 400,
                    start: 1,
                    space: 0,
                    layout: 'fullwidth',
                    loop: true,
                    preload: 0,
                    instantStartLayers: false,
                    autoplay: true,
                    overPause: true,
                    view: "fadeBasic"
                });
                // $(window).trigger('resize');
                $('.master-skeleton-loader').remove();

            } catch (err) {
                console.error(err);
                removeErrorMasterSliderContainer($bannerSlider);
            }
        }


    }

}

function productDetailSlider() {
    var bSlider = null;
  
    var $productSlider = $('#productSlider');
    if ($productSlider.length > 0) {
        if ($productSlider.find('.ms-view').length == 0) {
            try {

                bSlider = new MasterSlider();

                bSlider.control(
                    'thumblist', {
                        autohide: false,
                        overVideo: true,
                        dir: 'h',
                        speed: 17,
                        inset: false,
                        arrows: false,
                        hover: false,
                        customClass: '',
                        align: 'bottom',
                        type: 'thumbs',
                        margin: 5,
                        width: 100,
                        height: 100,
                        space: 5,
                        fillMode: 'fill'
                    });

                bSlider.setup('productSlider', {
                    width: 500,
                    height: 500,
                    space: 5,
                    view: 'fadeBasic'
                });
                // $(window).trigger('resize');
                $('.master-skeleton-loader').remove();

            } catch (err) {
                console.log(err);
                removeErrorMasterSliderContainer($productSlider);
            }
        }


    }
   
}

function goToTop() {
    var $scrollToTop = $('.scrollToTop');
    $(window).scroll(function () {
        if ($(this).scrollTop() > 300) {
            $scrollToTop.fadeIn();
        } else {
            $scrollToTop.fadeOut();
        }
    });

    //Click event to scroll to top
    var $rootElemHtmlBody = $('html, body');
    $scrollToTop.click(function () {
        $rootElemHtmlBody.animate({
            scrollTop: 0
        }, 800);
        return false;
    });
}


function question() {
    var $htmlRoot = $('html, body');
    if (window.JUMP_DIR != '') {
        var $q4Elem = $("#" + window.JUMP_DIR);
        if ($q4Elem.length > 0) {
            $htmlRoot.animate({
                scrollTop: $q4Elem.offset().top
            }, 2000);

        }
    }

    var $questions =
        $('.questions');

    var $questionsTitle =
        $questions.find('li > h3');

    $questionsTitle.prepend('<i class="fa fa-caret-right" aria-hidden="true"></i> ');

    $questionsTitle.click(function (e) {

        var $expand = $(this).find('i');
        // console.log($expand);
        if ($expand.hasClass('fa-caret-right')) {
            //open
            var $answer =
                $(this).next();
            $answer.show();

            $expand.removeClass('fa-caret-right')
                .addClass('fa-caret-down');
        } else {
            //close
            var $answer =
                $(this).next();
            $answer.hide();

            $expand.removeClass('fa-caret-down')
                .addClass('fa-caret-right');
        }

    });

    var $answers =
        $questions.find('li > div');
    $answers.hide();

}

function blogSharing() {
    initJsSocial($('#blogSharing'));
}

function productSharing() {
    initJsSocial($('#productSharing'));
}

function initJsSocial($targetElem) {
    if ($targetElem.length > 0) {
        $targetElem.jsSocials({
            shares: ["facebook", "linkedin", "twitter"]
        });
    }
}

function loadGoogleFont() {
    Pace.on('done', function () {
        WebFont.load({
            timeout: 2000,
            google: {
                families: [
                    'Roboto&display=swap',
                    'Noto Sans TC:100,300,500,700,900&display=swap'
                ]
            }
        });
    });
}